<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 600 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              female
            </v-icon>
            Gender
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Gender
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <v-row>
            <!-- Audience Section -->
            <v-col
              v-for="kind in possibleKinds"
              :key="kind"
              cols="12"
              :md="12 / possibleKinds.length"
            >
              <div class="mb-1 d-flex">
                <div class="d-flex align-center flex-grow-1">
                  <v-icon
                    :class="{ 'black--text': isThemeLight }"
                    class="mr-1"
                    left
                  >
                    {{ kind === 'influencer' ? 'volume_down' : 'people' }}
                  </v-icon>

                  {{ kind === 'likers' ? 'Engagers' : capitalizeString(kind) }}
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="isThemeLight ? 'black' : null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>

                  <span>
                    {{ tooltipTexts[kind] }}
                  </span>
                </v-tooltip>
              </div>

              <v-radio-group
                v-model="form[kind]"
                @change="submitForm(kind)"
                hide-details
              >
                <v-radio
                  v-for="(item, index) in options[kind]"
                  :key="`${kind}-${index}`"
                  :label="item.label"
                  :value="index"
                ></v-radio>
              </v-radio-group>

              <!-- Show this weight selector if the value is either male or female -->
              <weight-selector
                v-if="kind !== 'influencer' && form[kind] !== 0"
                v-model="form[kind + 'Weight']"
                @change="submitForm(kind)"
                class="mt-3"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

// used throughout the code
const filterName = "gender"

// define possible options
const audienceOptions = [
  {
    label: "Any",
    value: null,
  },
  {
    label: "Male",
    value: "MALE",
  },
  {
    label: "Female",
    value: "FEMALE",
  }
]

const influencerOptions = [
  ...audienceOptions,
  {
    label: "Male or Female",
    value: "KNOWN",
  },
  {
    label: "Gender Neutral",
    value: "UNKNOWN",
  }
]

const originalForm = () => ({
  likers: 0,
  followers: 0,
  influencer: 0,
  likersWeight: 0.5,
  followersWeight: 0.5
})

export default {
  name: "FilterGender",

  components: {
    WeightSelector,
  },

  props: {
    platform: String
  },

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    options: {
      likers: audienceOptions,
      followers: audienceOptions,
      influencer: influencerOptions
    },

    tooltipTexts: {
      likers: "Identify influencers by their audience gender. We determine gender of audience by analyzing profile photos, name and text in profile bio and selfies in recent posts.",
      followers: "Identify influencers by their audience gender. We determine gender of audience by analyzing profile photos, name and text in profile bio and selfies in recent posts.",
      influencer: "Identify influencers by gender. We determine gender of influencers by analyzing profile photos, name and text in profile bio and selfies in recent posts."
    }
  }),

  computed: {
    possibleKinds() {
      const map = {
        instagram: ['likers', 'followers', 'influencer'],
        tiktok: ['followers', 'influencer'],
        youtube: ['followers', 'influencer'],
      }

      return map[this.platform] || ['influencer']
    },

    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    appliedFilterFor() {
      return {
        likers: this.$store.getters["influencerDiscovery/findFilterByTypeAndKind"](filterName, "likers"),
        followers: this.$store.getters["influencerDiscovery/findFilterByTypeAndKind"](filterName, "followers"),
        influencer: this.$store.getters["influencerDiscovery/findFilterByTypeAndKind"](filterName, "influencer"),
      }
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters["influencerDiscovery/findFiltersByType"](filterName).length)
    }
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText({ kind, label }) {
      return `${kind === 'likers' ? 'Engagers' : capitalizeString(kind)} Gender: ${label}` + (kind !== 'influencer' ? ` | >${this.form[kind + 'Weight'] * 100}%` : '')
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm(kind) {
      const selectedItem = this.options[kind][this.form[kind]]

      // Check if the kind is one of likers or followers
      if (kind !== "influencer") {
        // Remove the other one from the filters
        const _kind = (kind === "likers" ? "followers" : "likers")

        if (this.appliedFilterFor[_kind]) {
          this.$store.dispatch("influencerDiscovery/removeFilter", this.appliedFilterFor[_kind].id)
        }
      }

      // if there's some value, then update the filter
      if (selectedItem.value) {
        this.$store.dispatch("influencerDiscovery/replaceFilter", {
          type: filterName,
          data: {
            color: "pink lighten-5",
            icon: "female",
            iconColor: "pink",
            kind,
            text: this.computeFilterText({ kind, label: selectedItem.label }),
            inputs: {
              ...selectedItem,
              weight: this.form[kind + 'Weight'] || null
            }
          }
        })
      }
      // otherwise remove the filter
      else {
        // if some other filter has been applied for this kind
        if (this.appliedFilterFor[kind]) {
          this.$store.dispatch("influencerDiscovery/removeFilter", this.appliedFilterFor[kind].id)
        }
      }
    },
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      this.form[e.detail.item.data.kind] = 0
    })

    // subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // now that it is the one we want to see, continue
      const index = this.options[e.detail.item.data.kind].findIndex((search) => search.value === e.detail.item.data.inputs.value)
      this.form[e.detail.item.data.kind] = index
    })
  }
}
</script>
